<template>
  <div>
    <Loader v-if="loading['processStage/getActions']" />
    <form ref="form" class="form" novalidate="novalidate" id="st_process_stage_form">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label> {{ fields.name.label }} * </label>
            <st-input-text
              v-model="model[fields.name.name]"
              :ref="fields.name.name"
              :name="fields.name.name"
            />
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label> {{ fields.key.label }} * </label>
            <b-form-select
              v-model="model[fields.key.name]"
              :options="actionOptions"
              :ref="fields.key.name"
              :name="fields.key.name"
            >
            </b-form-select>
          </div>
        </div>
      </div>
      <div v-show="showVariables">
        <div class="form-group" v-show="!hideInputVariables">
          <label> {{ fields.file_status.label }} * </label>
          <b-form-select
            v-model="model[fields.file_status.name]"
            :options="fields.file_status.options"
            :ref="fields.file_status.name"
            :name="fields.file_status.name"
            :disabled="!showVariables"
          >
          </b-form-select>
        </div>
        <div class="form-group" v-show="fields.application_status.options.length">
          <label> {{ fields.application_status.label }} * </label>
          <b-form-select
            v-model="model[fields.application_status.name]"
            :options="fields.application_status.options"
            :ref="fields.application_status.name"
            :name="fields.application_status.name"
            :disabled="!showVariables"
          >
          </b-form-select>
        </div>
        <div class="form-group" v-show="!hideInputVariables">
          <label> {{ fields.file_type.label }} * </label>
          <b-form-select
            v-model="model[fields.file_type.name]"
            :options="fields.file_type.options"
            :ref="fields.file_type.name"
            :name="fields.file_type.name"
            :disabled="!showVariables"
          >
          </b-form-select>
        </div>
        <div class="form-group" v-show="showSignCheckbox">
          <label> {{ fields.function_type.label }} * </label>
          <st-autocomplete
            v-model="model[fields.function_type.name]"
            :options="fields.function_type.options"
            :ref="fields.function_type.name"
            :name="fields.function_type.name"
            :disabled="!showVariables || isAssignedUserChecked"
            :fields="fields"
            :formValidation="fv"
          />
        </div>
      </div>
      <div v-show="showSignCheckbox || showReturnOption">
        <div class="form-group">
          <label>
            {{ fields.return_action_id.label }}
          </label>
          <b-form-select
            v-model="model[fields.return_action_id.name]"
            :ref="fields.return_action_id.name"
            :name="fields.return_action_id.name"
          >
            <b-form-select-option value="applicationReview">{{ getActionTextByKey('applicationReview') }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
      <div v-show="showSignCheckbox">
        <b-form-checkbox
          size="lg"
          v-model="model[fields.sign_by_assigned_user.name]"
          :value="true"
          :unchecked-value="false"
          @change="removeFunctionType"
        >
          {{ fields.sign_by_assigned_user.label }}
        </b-form-checkbox>
      </div>
      <div v-show="showDocumentTemplate">
        <div class="row my-3">
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label>
                {{ fields.extra_document_template_id.label }} *
              </label>
              <st-autocomplete
                v-model="model[fields.extra_document_template_id.name]"
                :options="fields.extra_document_template_id.options"
                :ref="fields.extra_document_template_id.name"
                :name="fields.extra_document_template_id.name"
                :fields="fields"
                :formValidation="fv"
              >
              </st-autocomplete>
            </div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label>
                {{ fields.extra_document_visibility.label }} 
              </label>
              <st-autocomplete
                v-model="model[fields.extra_document_visibility.name]"
                :options="fields.extra_document_visibility.options"
                :ref="fields.extra_document_visibility.name"
                :name="fields.extra_document_visibility.name"
                :fields="fields"
                :formValidation="fv"
              >
              </st-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { createFormValidation } from "@/shared/utils/create-form-validation";

import { FormSchema } from "@/shared/form/form-schema";
import { ProcessStageModel } from "@/modules/process-editor/models/process-stage-model";
import { mapActions, mapGetters } from "vuex";

const { fields } = ProcessStageModel;

const initialFormSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.key,
  fields.file_status,
  fields.file_type,
  fields.sign_by_assigned_user,
  fields.function_type,
  fields.application_status,
  fields.return_action_id,
  fields.extra_document_template_id,
  fields.extra_document_visibility,
]);

const actionNames = {
  GenerateDocument: "generateDocument",
  SignDocument: "signDocument",
  ApplicationReview: "applicationReview",
  PreSignDocument: "preSignDocument",
  HumanReview: "humanReview",
  SignApplicantUser: "signApplicantUser",
  GenerateExtraDocument: "generateExtraDocument",
};

export default {
  name: "ProcessStageForm",
  props: {
    selectedStep: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    createdProcess: {
      type: Object,
      default: () => ({}),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    processId: String,
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formSchema: initialFormSchema,
      rules: initialFormSchema.rules(),
      fields,
      fv: null,
      model: {},
      showVariables: false,
      hideInputVariables: false,
      showSignCheckbox: false,
      showReturnOption: false,
      showDocumentTemplate: false,
    };
  },
  computed: {
    ...mapGetters({
      actions: "processEditor/stageForm/actions",
      loading: "shared/loading",
    }),
    actionOptions() {
      return this.fields.key.options.filter(
        (option) => option.value !== "start" && option.value !== "end"
      );
    },
    isAssignedUserChecked() {
      return this.model.sign_by_assigned_user;
    },
  },
  watch: {
    "model.key": {
      deep: true,
      immediate: false,
      handler(selectedAction) {
        const action = this.actions.find(
          (action) => action.key === selectedAction
        );
        if (action) {
          this.showDocumentTemplate =
            action.key === actionNames.GenerateExtraDocument;

          this.showVariables =
            action.variables || action.outputVariables ? true : false;
          this.hideInputVariables = !action.variables && action.outputVariables;

          if (action.variables || action.outputVariables) {
            this.showSignCheckbox = !!(
              action?.topic_name &&
              action.topic_name === actionNames.SignDocument
            );
            this.showReturnOption = !!(
              action?.topic_name &&
              action.topic_name === actionNames.SignApplicantUser
            );

            const actionField = this.fields.key;
            actionField.children.forEach((child) => {
              const el = this.fields[child.name];
              el.fetchParams = { [child.key]: action[child.key] };
              el.updateOptions();
            });
          } else {
            this.showSignCheckbox = false;
            this.showReturnOption =
              action.topic_name === actionNames.HumanReview;
          }
        } else {
          this.showVariables = false;
          this.showDocumentTemplate = false;
        }
        this.$refs.form.reset();
        this.buildFormSchema(selectedAction);
      },
    },
  },
  mounted() {
    this.fv = createFormValidation("st_process_stage_form", this.rules, []);
  },
  async created() {
    await this.actionsList();
    this.model = this.editMode
      ? initialFormSchema.initialValues(this.selectedStep)
      : initialFormSchema.initialValues({});

    if (this.editMode && this.model.key.indexOf("_") !== -1) {
      this.model.key = this.model.key.slice(0, this.model.key.indexOf("_"));
    }

    if (this.selectedStep?.inputParameters) {
      this.model.file_status = this.selectedStep.inputParameters.file_status;
      this.model.file_type = this.selectedStep.inputParameters.file_type;

      if (this.selectedStep.inputParameters?.function_type) {
        this.model.function_type =
          this.selectedStep.inputParameters.function_type;
      }

      if (this.selectedStep.inputParameters?.sign_by_assigned_user) {
        this.model.sign_by_assigned_user =
          this.selectedStep.inputParameters.sign_by_assigned_user;
      }

      if (this.selectedStep.inputParameters?.extra_document_template_id) {
        this.model.extra_document_template_id =
          this.selectedStep.inputParameters.extra_document_template_id;
        this.model.extra_document_visibility =
          this.selectedStep.inputParameters.extra_document_visibility;
      }
    }

    if (this.selectedStep?.outputParameters) {
      this.model.application_status =
        this.selectedStep.outputParameters?.application_status;
    }

    if (this.selectedStep?.return_action_id) {
      this.model.return_action_id = this.actions.find(
        (el) => el.id === this.selectedStep.return_action_id
      )?.key;
    }

    if (this.editMode) {
      this.buildFormSchema(this.selectedStep.key.split("_")[0]);
    }
  },
  methods: {
    ...mapActions({
      addStep: "processEditor/stageForm/create",
      updateStep: "processEditor/stageForm/update",
      actionsList: "processEditor/stageForm/actions",
    }),
    hide() {
      this.$emit("cancelForm");
    },
    manageProcess() {
      this.fv.validate().then((status) => {
        if (status === "Valid") {
          if (this.editMode) {
            this.onUpdateStep();
          } else {
            this.onAddStep();
          }
        }
      });
    },
    onAddStep() {
      const cast = this.formSchema.cast(this.model);
      const selectedAction = this.actions.find(
        (action) => action.key === cast.key
      );

      const inputParameters = {
        file_status: cast.file_status,
        file_type: cast.file_type,
      };
      const outputParameters = {};

      if (cast?.function_type) {
        inputParameters.function_type = cast.function_type;
      }

      if (cast?.sign_by_assigned_user) {
        inputParameters.sign_by_assigned_user = cast.sign_by_assigned_user;
      }

      if (cast?.application_status) {
        outputParameters.application_status = cast.application_status;
      }

      const payload = {
        key: selectedAction.key,
        name: cast.name,
        description: selectedAction.description,
        action_id: selectedAction.id,
        action_name: selectedAction.name,
        workflow_id: !!this.processId ? this.processId : this.createdProcess.id,
        order: this.steps.length - 1,
      };

      if (cast?.return_action_id) {
        const returnAction = this.actions.find(
          (action) => action.key === cast.return_action_id
        );
        payload.return_action_id = returnAction.id;
      }

      if (this.showVariables) {
        payload.inputParameters = inputParameters;
        payload.outputParameters = outputParameters;
      }

      if (selectedAction.key === "generateExtraDocument") {
        payload.inputParameters = {
          extra_document_template_id: cast?.extra_document_template_id,
          extra_document_visibility: cast?.extra_document_visibility,
        };
      }

      this.addStep(payload).then(() => {
        this.hide();
        this.$emit("submit");
      });
    },
    onUpdateStep() {
      const { id, ...data } = this.model;
      const updatedAction = this.actions.find(
        (action) => action.key === this.model.key
      );

      const specificInputParams = {};
      const outputParameters = {};

      if (data?.function_type) {
        specificInputParams.function_type = data.function_type;
      }

      if (data?.sign_by_assigned_user) {
        specificInputParams.sign_by_assigned_user = data.sign_by_assigned_user;
      }

      if (data?.application_status) {
        outputParameters.application_status = data.application_status;
      }

      const inputParameters = {
        file_status: data.file_status,
        file_type: data.file_type,
        ...specificInputParams,
      };

      const payload = {
        ...data,
        description: updatedAction.description,
        action_id: updatedAction.id,
        action_name: updatedAction.name,
      };

      if (data?.return_action_id) {
        const returnAction = this.actions.find(
          (action) => action.key === this.model.return_action_id
        );
        if (returnAction) {
          payload.return_action_id = returnAction.id;
        }
      }

      if (this.showVariables) {
        payload.inputParameters = inputParameters;
        payload.outputParameters = outputParameters;
      }

      if (updatedAction.key === "generateExtraDocument") {
        payload.inputParameters = {
          extra_document_template_id: data?.extra_document_template_id,
          extra_document_visibility: data?.extra_document_visibility,
        };
      }

      this.updateStep({ id: this.model.id, payload }).then(() => {
        this.hide();
        this.$emit("submit");
      });
    },
    removeFunctionType() {
      if (this.model.sign_by_assigned_user) {
        this.model.function_type = "";
      }
      this.buildFormSchema(actionNames.SignDocument);
    },
    buildFormSchema(actionName) {
      let baseFields = [fields.id, fields.name, fields.key];
      switch (actionName) {
        case actionNames.GenerateDocument:
        case actionNames.PreSignDocument:
          baseFields = baseFields.concat([
            fields.file_status,
            fields.file_type,
          ]);
          break;
        case actionNames.SignDocument:
          baseFields = baseFields.concat([
            fields.file_status,
            fields.application_status,
            fields.file_type,
            fields.function_type,
            fields.return_action_id,
            fields.sign_by_assigned_user
          ]);
          if (this.model.sign_by_assigned_user) {
            baseFields = baseFields.filter(f => f !== fields.function_type);
          }
          break;
        case actionNames.HumanReview:
          baseFields = baseFields.concat([fields.return_action_id]);
          break;
        case actionNames.SignApplicantUser:
          baseFields = baseFields.concat([
            fields.application_status,
            fields.return_action_id,
          ]);
          break;
        case actionNames.GenerateExtraDocument:
          baseFields = baseFields.concat([
            fields.extra_document_template_id,
            fields.extra_document_visibility,
          ]);
          break;
        default:
          break;
      }
      this.formSchema = new FormSchema(baseFields);
      this.rules = this.formSchema.rules();
      this.fv.resetForm(true); 
      this.fv = createFormValidation("st_process_stage_form", this.rules);
    },
    getActionTextByKey(actionKey) {
      return fields.key.options.find(o => o.value === actionKey)?.text;
    }
  },
};
</script>
